//Разметка добавления числовых данных

import React from 'react';
import './ConstData.css';
import { DataBlock, ExceptionBlock } from '../../molecules'
import { AddButton } from '../../atoms'
import { useDispatch, useSelector } from 'react-redux'
import { postLib } from '../../../store/api';
import api from "../../../utils/api";

export function ConstData (props) {
  const dispatch = useDispatch();  
  const { lib, status } = useSelector(state => state.lib)
 
  function handleAdd() {
    dispatch(postLib({lib:lib, except: [] }))
  }

  function handleSitemap() {
    api.getSitemap()
    .then(data=> {
      const blob = new Blob([data], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sitemap.xml';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
    })
  }

  return (
    <main className='data'>
      {/*status === 'resolved' &&
      <AddButton 
        onClick={handleAdd}
        type=''
        title='Добавить библиотеку'
  />*/}
      <DataBlock 
        nameRu='Витамины'
        nameEn= 'vitamins'
      />
      <DataBlock 
        nameRu='Бренды'
        nameEn= 'brands'
      />
      <DataBlock 
        nameRu='Тип питания'
        nameEn= 'typeFoods'
      />
      <DataBlock 
        nameRu='Меры веса'
        nameEn= 'measure'
      />
      <DataBlock 
        nameRu='Блюда'
        nameEn= 'dish'
      />
      <DataBlock 
        nameRu='Категории у ингредиентов'
        nameEn= 'catIn'
      />
      <DataBlock 
        nameRu='Инвентарь'
        nameEn= 'kitchenware'
      />

      <ExceptionBlock 
        nameRu='Исключения'
        nameEn= 'except'
      />
      <button className='menuweek-button color' onClick={handleSitemap}>Скачать sitemap</button>
    </main>
  )
}