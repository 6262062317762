import React from 'react';
import './UserActiv.css'; 
import api from "../../../utils/api";
import { useDispatch } from 'react-redux' 

export function UserActiv(props) { 
  const [activ, setActiv] = React.useState([]);

  React.useEffect(() => {
    api.getUserActivity()
    .then(res=> {
        setActiv(res)
    })
    .catch((err) => {
      console.log(err)
    })
  }, []);





  const filteredData = activ.reduce((acc, cur) => {
    if (!acc[cur.userID]) {
      acc[cur.userID] = { 
          userID: cur.userID, 
          login: 0, 
          visit: 0, 
          search: 0, 
          dfoodlist:0, 
          dplan: 0, 
          foodlist: 0, 
          gener: 0, 
          settings: 0, 
          upplan: 0,  
          reset: 0,
          firstTime: new Date(cur.time), 
          lastTime: new Date(cur.time)
        };
    }
    acc[cur.userID][cur.actionType] += 1;
    if (new Date(cur.time) <= new Date(acc[cur.userID].firstTime)) {
        acc[cur.userID].firstTime = new Date(cur.time);
    }
    if (new Date(cur.time) > new Date(acc[cur.userID].lastTime)) {
        acc[cur.userID].lastTime = new Date(cur.time);
    }
    return acc;
  }, {});

const dataArray = Object.values(filteredData);
console.log( Object.values(filteredData))


  return (
    <main className='content'>
        <div className='table__thead'>
        <ul className='activ'>
          <li className='activ_id'>userId</li>  
          <li className='activ_item'>ЛОГИН</li>
          <li className='activ_item'>ВИЗИТ</li> 
          <li className='activ_item'>ПОИСК</li>
          <li className='activ_item'>dfoodlist</li> 
          <li className='activ_item'>dplan</li> 
          <li className='activ_item'>foodlist</li>
          <li className='activ_item'>gener</li>
          <li className='activ_item'>settings</li> 
          <li className='activ_item'>upplan</li>
          <li className='activ_item'>reset</li>
          <li className='activ_data'>Дата1</li>
          <li className='activ_data'>Дата2</li>

        </ul>
      </div>
      {dataArray.map(i=> (
          <ul className='activ'>
               <li className='activ_id'>{i.userID}</li> 
          <li className='activ_item'> {i.login}</li>
          <li className='activ_item'> {i.visit}</li> 
          <li className='activ_item'>{i.search}</li>
          <li className='activ_item'>{i.dfoodlist}</li> 
          <li className='activ_item'>{i.dplan}</li> 
          <li className='activ_item'>{i.foodlist}</li>
          <li className='activ_item'>{i.gener}</li>
          <li className='activ_item'>{i.settings}</li> 
          <li className='activ_item'>{i.upplan}</li>
          <li className='activ_item'>{i.reset}</li>
          <li className='activ_data'>{`${i.firstTime.getDate()}.${i.firstTime.getMonth() + 1}.${i.firstTime.getFullYear()} ${i.firstTime.getHours()}:${i.firstTime.getMinutes()}`}</li> 
          <li className='activ_data'>{`${i.lastTime.getDate()}.${i.lastTime.getMonth() + 1}.${i.lastTime.getFullYear()} ${i.lastTime.getHours()}:${i.lastTime.getMinutes()}`}</li>
            
          </ul>
      ))}
    </main>
)
   
}
