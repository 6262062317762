import { createAsyncThunk } from "@reduxjs/toolkit";
 //    export const BASE_URL = 'http://localhost:3002'
    export const BASE_URL = 'https://api.menubook.ru';

  export const getIngredients = createAsyncThunk(
    'ingredients/getIngredients',
    async (_, {rejectWhithValue}) => {
      try { 
            const response = await fetch(`${BASE_URL}/ingredients`, {
              method: 'GET',
              headers: {
               'Content-Type': 'application/json',
              },
            })
            if(!response.ok) {
              throw new Error('Server Error!')
            }
            const data = await response.json();
            return data;
          } catch (error) {
              return rejectWhithValue(error.message)
          }
    }
  )

  // Обновление настроек пользователя
  export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (settings, {rejectWhithValue}) => {  
      try {
        const response = await fetch(`${BASE_URL}/users/me/settings`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({settings: settings})
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })
  
  export const postIngredients = createAsyncThunk(
    'ingredients/postIngredients',
    async (arr, {rejectWithValue}) => {
      try { 
            const response = await fetch(`${BASE_URL}/ingredients`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify(arr)
            })
            if(!response.ok) {
              const error = await response.json();
              throw new Error(error.statusCode)
            }
            const data = await response.json();
            return data;
          } catch (error) {
              return rejectWithValue(error.message)
          }
    }
  )

  //получение данных создателя рецепта(для отображения в источнике)
  export const getUserID = createAsyncThunk(
    'user/getUserID',
    async (text, {rejectWhithValue}) => {  
      try {
        const response = await fetch(`${BASE_URL}/users/find`, {
          method: 'POST',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({text: text}),
        })
        if(!response.ok) { 
          throw new Error(response.status)
        }
        const data = await response.json(); 
        return data;
      } catch (error) {
        if (!error.response) {
          throw error
        } 
        return rejectWhithValue(error.response.data)
      }
  })

  export const getUsersPage = createAsyncThunk(
    'user/page',
    async (arr, {rejectWhithValue}) => {  
      try {
        const response = await fetch(`${BASE_URL}/users/search?page=${arr.currentPage}&limit=${arr.limit}&sort=${arr.sort}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }, 
        credentials: 'include',
        });
        if(!response.ok) {
        throw new Error('Server Error!')
        }
        const data = await response.json();
        const count = response.headers.get('Total-Card')
        return ({data, count});
      } catch (error) {
        return rejectWhithValue(error.message)
      }
  })
  

  export const editVisor = createAsyncThunk(
    'user/editVisor',
    async (arr, {rejectWhithValue}) => {  
      try {
        const response = await fetch(`${BASE_URL}/users/me/visor/${arr.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        }, 
        credentials: 'include',
        body: JSON.stringify({supervisor: arr.supervisor,current: arr.current})
        });
        if(!response.ok) {
        throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWhithValue(error.message)
      }
  })

  export const fetchFilter = createAsyncThunk(
    'resipes/page',
    async (arr, {rejectWhithValue}) => {  
      try {
        const response = await fetch(`${BASE_URL}/recipe/search?page=${arr.currentPage}&limit=${arr.limit}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }, 
        credentials: 'include',
        });
        if(!response.ok) {
        throw new Error('Server Error!')
        }
        const data = await response.json();
        const count = response.headers.get('Total-Card')
        return ({data, count});
      } catch (error) {
        return rejectWhithValue(error.message)
      }
  })
  export const deleteIngredients = createAsyncThunk(
    'ingredients/deleteIngredients',
    async (id, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/ingredients/${id}`, {
          method: 'DELETE',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  export const editIngredients = createAsyncThunk(
    'ingredients/editIngredients',
    async (arr, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/ingredients/${arr.id}`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            name: arr.values.name,
            nameRu: arr.values.name,
            trait: arr.values.trait,
            nameEn: arr.values.nameEn,
            brands: arr.choiceIngredient,
            mainMeasure: arr.values.mainMeasure,
            cooking: arr.values.cooking,
            frying: arr.values.frying,
            season: arr.values.season,
            purified: arr.values.purified,
            packing: [],
            calories: {
              protein: arr.values.protein,
              fats: arr.values.fats,
              carb: arr.values.carb,
              calories: arr.values.calories,
              digest: arr.values.digest,
            },
            measure: arr.measures,
            vitamins: arr.vitamins,
            accusativeCase: '',
            genitiveCase: '',
            instrumentalCase: '',
            category: arr.values.category,
            desc: `${arr.values.desc ? arr.values.desc: ''}`,
            image: [],
            choisMeasure: arr.values.mainMeasure,
            weight: 0,
            price: {
              rate: arr.values.price,
              weightprice: arr.values.weightprice,
              measureprice: arr.values.mainMeasure,
            },
          })
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  export const postRecipe = createAsyncThunk(
    'recipe/addrecipe',
    async (arr, {rejectWhithValue}) => {
     
      try {
        const response = await fetch(`${BASE_URL}/recipe`, {
          method: 'POST',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(arr)
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  export const getRecipe = createAsyncThunk(
    'recipe/getrecipe',
    async (_, {rejectWhithValue}) => {
     
      try {
        const response = await fetch(`${BASE_URL}/recipes`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  export const getRecipeId = createAsyncThunk(
    'recipe/getrecipeId',
    async (id, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/recipe/${id}`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  export const getUser = createAsyncThunk(
    'user/getUser',
    async (_, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/users/me`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  export const deleteRecipe = createAsyncThunk(
    'recipe/deleteRecipe',
    async (id, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/recipe/${id}`, {
          method: 'DELETE',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  export const editRecipe = createAsyncThunk(
    'recipe/editRecipe',
    async (arr, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/recipe/${arr.id}`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            name: arr.values.title,
            nameRu: arr.values.title,
            description: arr.desc,
            heat: arr.values.heat,
            fresh: arr.values.fresh,
            category: arr.values.category,
            kitchenware: arr.kitchenware,
            preparation: arr.values.preparation,
            activeCooking: arr.values.activeCooking,
            advice: arr.values.advice,
            desc:[],
            thermo: arr.values.thermo,
            minPortion: arr.values.minPortion,
            image: arr.imageBlob,
            ingredients: arr.ingredientsData,
            servings: arr.capacity,
            time: `${(parseInt(arr.values.hours === undefined || arr.values.hours === '') ? 0 : arr.values.hours )*60+parseInt((arr.values.minute ===undefined || arr.values.minute === '') ? 0 : arr.values.minute)}`,
            typeOfDiet: arr.types,
            calories: arr.result,
            link: arr.values.author,
            metaDesc: arr.values.metaDesc,
          })
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  export const editRecipeMeta = createAsyncThunk(
    'recipe/editRecipeMeta',
    async (arr, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/recipe/${arr.id}`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(arr)
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })
 


  // Добавление данных в библиотеку
  export const postLibrary = createAsyncThunk(
    'lib/postLibrary',
    async (arr, {rejectWithValue}) => { 
      try { 
            const response = await fetch(`${BASE_URL}/lib/${arr.name}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({data: arr.values})
              
            })
            if(!response.ok) {
              const error = await response.json();
              throw new Error(error.statusCode)
            }
            const data = await response.json();
            return data;
          } catch (error) {
            console.log(error)
              return rejectWithValue(error.message)
          }
    }
  )

// Получение данных из библиотеки
  export const getLibrary = createAsyncThunk(
    'lib/getLibrary',
    async (_, {rejectWhithValue}) => {
      try { 
            const response = await fetch(`${BASE_URL}/lib`, {
              method: 'GET',
              headers: {
               'Content-Type': 'application/json',
              },
              credentials: 'include',
            })
            if(!response.ok) {
              throw new Error('Server Error!')
            }
            const data = await response.json();
            return data;
          } catch (error) {
              return rejectWhithValue(error.message)
          }
    }
  )

  //Первое заполнение библиотеки
  export const postLib = createAsyncThunk(
    'lib/postLib',
    async (arr, {rejectWithValue}) => {
      try { 
            const response = await fetch(`${BASE_URL}/lib`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({
                 vitamins: arr.lib.vitamins,
                 brands: arr.lib.brands,
                 typeFoods: arr.lib.typeFoods,
                 measure: arr.lib.measure,
                 catIn: arr.lib.catIn,
                 dish: arr.lib.dish,
                 except: arr.except
              })
            })
            if(!response.ok) {
              const error = await response.json();
              throw new Error(error.statusCode)
            } 
            const data = await response.json();
            return data;
          } catch (error) {
            console.log(error)
              return rejectWithValue(error.message)
          }
    }
  )

  //Удаление данных из библиотеки
  export const deleteLibrary = createAsyncThunk(
    'lib/deleteRecipe',
    async (arr, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/library/${arr.id}`, {
          method: 'DELETE',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({name: arr.name})
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  export const updateLibrary = createAsyncThunk(
    'lib/updateLibrary',
    async (arr, {rejectWhithValue}) => {
      try { 
        const response = await fetch(`${BASE_URL}/lib/${arr.id}`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({data: arr.values, name: arr.name})
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

   //Получение списка меню из базы
   export const getPlan = createAsyncThunk(
    'plan/getplan',
    async (_, {rejectWhithValue}) => {
     
      try {
        const response = await fetch(`${BASE_URL}/plan`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })