import { typeFoods, mealsArray, weekdayArray } from '../constants/constants'
   export const BASE_URL = 'https://api.menubook.ru';
     //      export const BASE_URL = 'http://localhost:3002';

const getResponsData = (res)=> {
  if(res.ok) {
    return res.json()
  
   }else {
     return Promise.reject(res.status)
   } 
}

export const register = (email, password, name) => {
  return fetch(`${BASE_URL}/signup`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      email, 
      password, 
      name,
      bonus: {
        summ: 0,
        recipeActive: [],
        sumTraffick: [],
        recipeInActive: [],
      },
      settings: {
        sumFamily: 1,
        typeOfDiet: typeFoods,
        powerMode: mealsArray,
        period: weekdayArray,
        stopProducts: [],
        cookingTime: 60
      },
      acces: true,
      subscriptions: {
        title: "Стандарт", 
        subs: "standart", 
        text:"Подходит для тех чья семья питается по единому графику питания", 
        price: 99,
        id: "99mbp",
        stocks: "60 дней бесплатно, без тестовых платежей",
        active: true
      },
      dateAcces: new Date().getTime() + 3153600000000,
      finance: {
        summ: 0,
        receiptTraffic: [],
        writeDownsTraffic: [],
      }
    })
  })
  .then(getResponsData)
};

export const authorize = (email, password) => {
  return fetch(`${BASE_URL}/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({email, password})
  })
  .then(getResponsData);
}; 
 
export const getContent = () => {
  return fetch(`${BASE_URL}/users/me`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include",
  })
  .then(getResponsData);
} 

export const signOut = () => {
  return fetch(`${BASE_URL}/signout`, {
    method: "POST",
    credentials: "include",
  })
  .then(getResponsData);
}
